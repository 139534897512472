import React from "react";
import { HeaderGeneral } from "../components/header-general";
import { Layout } from "../components/layout";
import SEO from "../components/seo";
import { MaxWidth, READ_WIDTH } from "../utils/style";
import { useStaticQuery, graphql } from "gatsby";
import RTF from "../components/rtf";

const Impressum = () => {
  const data = useStaticQuery(graphql`
    {
      contentfulHtml(uid: { eq: "datenschutz" }) {
        content {
          json
        }
      }
    }
  `);

  return (
    <Layout>
      <SEO title="Impressum" />
      <HeaderGeneral small>
        <>
          <div className="circle-text">
            <h1>Datenschutz</h1>
          </div>
          <div
            className="circle"
            style={{
              backgroundImage: `url(${require("../images/datenschutz.jpg")})`
            }}
          />
        </>
      </HeaderGeneral>
      <MaxWidth greyGradient maxWidth={READ_WIDTH}>
        <RTF content={data.contentfulHtml.content.json} />
      </MaxWidth>
    </Layout>
  );
};

export default Impressum;
